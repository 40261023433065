import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { ThemeProvider } from "@mui/material/styles";
import { darkTheme } from "./themes/oc-dark-default";
import CssBaseline from "@mui/material/CssBaseline";
import { Authenticator } from "@aws-amplify/ui-react";

import * as serviceWorker from "./serviceWorker";
import { Amplify } from "aws-amplify";

const domainSeperator = process.env.REACT_APP_DOMAINSEP
  ? process.env.REACT_APP_DOMAINSEP
  : "-";
const domain =
  process.env.NODE_ENV === "development"
    ? `${process.env.REACT_APP_CUSTOMER}.skytrain.vmo.cloud`
    : window.location.hostname;
const url = `https://app${domainSeperator}${domain}/config`;
fetch(url)
  .then((response) => response.json())
  .then((data: any) => {
    localStorage.setItem("amplify_config", JSON.stringify(data));
    let config = JSON.parse(localStorage.getItem("amplify_config"));
    config = {
      ...config,
      API: {
        test: true,
      },
    };
    Amplify.configure(config);
    return config;
  })
  .then((data: any) => {
    ReactDOM.render(
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Authenticator.Provider>
          <App />
        </Authenticator.Provider>
      </ThemeProvider>,
      document.getElementById("root")
    );
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: http://bit.ly/CRA-PWA
    serviceWorker.unregister();
  });
