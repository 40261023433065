// Material UI Imports
import { createTheme, Theme, ThemeOptions } from "@mui/material/styles";
import { orange, red, green } from "@mui/material/colors";

declare module "@mui/material/styles" {
  interface Theme {
    custom?: {
      forms?: {
        button: string;
        buttonText: string;
        labels: string;
        inputText: string;
        inputBack: string;
        inputBorder: string;
        pristineColor: string;
        focusedColor: string;
        dirtyColor: string;
        errorColor: string;
      };
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    custom?: {
      forms?: {
        button: string;
        buttonText: string;
        labels: string;
        inputText: string;
        inputBack: string;
        inputBorder: string;
        pristineColor: string;
        focusedColor: string;
        dirtyColor: string;
        errorColor: string;
      };
    };
  }
}
export const themeVars: ThemeOptions = {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#1A2738",
          "& [data-amplify-authenticator] [data-amplify-router]": {
            borderStyle: `none`,
            borderWidth: "0",
            borderColor: "none",
          },
        },
      },
    },
  },
  typography: {
    subtitle1: {
      fontSize: 14,
      fontWeight: 300,
      color: "#8dabc4",
    },
    body1: { color: "#9fb1c1" },
    body2: { color: "#67859e" },
  },
  transitions: {
    duration: {
      enteringScreen: 500,
      leavingScreen: 200,
    },
  },
  palette: {
    mode: "dark",
    primary: {
      light: "#9fb1c1",
      main: "#0087C6",
      dark: "#0074aa",
      contrastText: "#ffffff",
    },
    secondary: {
      light: orange[300],
      main: "#d93800", //orange[500],
      dark: orange[900],
      contrastText: "#ffffff",
    },
    error: {
      light: red[800],
      main: "#800",
      dark: "#950000",
      contrastText: "#ffffff",
    },
    success: {
      light: green[800],
      main: "#080",
      dark: "#009500",
      contrastText: "#ffffff",
    },
    divider: "#000000",
    background: { default: "transparent" },
  },
  custom: {
    forms: {
      button: "#1A2738",
      buttonText: "#9fb1c1",
      labels: "#9fb1c1",
      inputText: "#a4bed6", //'#9fb1c1',
      inputBack: "#1A2738", //'#2c405a',
      inputBorder: "#334b69",
      pristineColor: "#9fb1c1",
      focusedColor: "#9fb1c1",
      dirtyColor: "#87Ed8C",
      errorColor: "#FF2C55",
    },
  },
};

export const darkTheme: Theme = createTheme(themeVars as ThemeOptions);
