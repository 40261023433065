import { useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";

interface IOCSignInProps {
  redirectURL: string;
}

function OCSignIn(props: IOCSignInProps): JSX.Element {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const { redirectURL } = props;
  useEffect(() => {
    if (authStatus === "authenticated") {
      localStorage.setItem("amplify-authenticator-authState", authStatus);
      window.location.href = redirectURL;
    }
  }, [user, authStatus, redirectURL]);
  return <></>;
}

export default OCSignIn;
